<template>
  <div>
    <div v-if="items && items.length > 0">
      <carousel
        v-if="items && items.length > 0"
        :per-page="1"
        :pagination-enabled="true"
        :paginationPadding="15"
        :paginationSize="15"
        :loop="true"
        :autoplay="true"
        :autoplay-timeout="6000"
      >
        <slide v-for="(item,index) in items" :key="index">
          <div class="box-testimony">
            <div class="title">
              <img :src="item.image" class="img-fluid" :alt="`imagem de ${item.name}`">
              <h6>
                {{item.name}}
              </h6>
            </div>
            <div class="description" v-html="item.description"></div>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>
<script>
import { Carousel, Slide } from 'vue-carousel';
export default {
  props: ['testimonies'],
  components: {
    Carousel,
    Slide
  },
  data(){
    return {
      items: [],
    }
  },
  mounted(){
    let el = this;
    $(document).ready(function() {
      el.items = el.testimonies;
    });
  }
}
</script>

<style lang="scss" scoped>
@import "resources/assets/sass/website/_variables.scss";
</style>