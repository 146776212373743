<template>
  <div>
    <carousel
      :per-page="1"
      :pagination-enabled="false"
      :paginationPadding="15"
      :paginationSize="15"
      :loop="true"
      :autoplay="true"
      :autoplay-timeout="6000"
    >
      <slide v-for="(item,index) in items" :key="index">
        <div :style="'background-image: url('+item.image+');'" class="banner-home">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div v-if="item.text || item.link" class="banner-box">
                  <h2 v-if="item.text">{{item.text}}</h2>
                  <a v-if="item.link" :href="item.link" target="_blank" class="btn btn-banner">{{item.button}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </slide>
    </carousel>
  </div>
</template>
<script>
import { Carousel, Slide } from 'vue-carousel';
export default {
  props: ['banners'],
  components: {
    Carousel,
    Slide
  },
  data(){
    return {
      items: [],
    }
  },
  mounted(){
    let el = this;
    $(document).ready(function() {
      el.items = el.banners;
    });
  }
}
</script>

<style lang="scss" scoped>
@import "resources/assets/sass/website/_variables.scss";

</style>