/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.Popper = require('popper.js');

window.$ = window.jQuery = require('jquery');

require('admin-lte');
require("bootstrap");

//window.Swal = require('sweetalert2');


window.Vue = require("vue");

import Vue from "vue";

import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);

import money from "v-money";
Vue.use(money, { precision: 4 });

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAoTgQ1QlYY5gZTaOekftElnSQM0sNIxvA'
  }
})

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
Vue.component("content-header", require("./cms/ContentHeader.vue").default);
Vue.component("data-table", require("./cms/DataTable.vue").default);
Vue.component("tabs", require("./cms/Tabs.vue").default);
Vue.component("ui-form", require("./cms/UIForm.vue").default);
Vue.component("ui-select", require("./cms/UISelect.vue").default);
Vue.component("ui-textarea", require("./cms/UITextarea.vue").default);
Vue.component("ui-money", require("./cms/UIMoney.vue").default);
Vue.component("ui-phone", require("./cms/UIPhone.vue").default);
Vue.component("ui-mask-input", require("./cms/UIMaskInput.vue").default);
Vue.component("alert", require("./cms/Alert.vue").default);
Vue.component("checkboxes", require("./cms/Checkboxes.vue").default);
Vue.component("radios", require("./cms/Radios.vue").default);
Vue.component("dropdown-list", require("./cms/DropdownList.vue").default);
Vue.component("dropdown-events", require("./cms/DropdownEvents.vue").default);
Vue.component("cidade-bairro", require("./cms/Cidade-bairro.vue").default);

Vue.component("carousel-home", require("./front/TheCarousel.vue").default);
Vue.component("newletter-form", require("./front/NewsletterForm.vue").default);
Vue.component("the-gallery", require("./front/TheGallery.vue").default);
Vue.component("contact-form", require("./front/ContactForm.vue").default);
Vue.component("interest-form", require("./front/InterestForm.vue").default);
Vue.component("testimonies-home", require("./front/TheTestimonies.vue").default);
Vue.component("the-map", require("./front/TheMap.vue").default);

window.openMenuMobile = () => {
  $('#menu-mobile').addClass('menu-mobile--open');
}

window.cloneMenuMobile = () => {
  $('#menu-mobile').removeClass('menu-mobile--open');
}

const app = new Vue({
  el: "#app"
});
