<template>
  <div>
    <GmapMap
      :center="{lat:latitude, lng:longitude}"
      :zoom="15"
      style="width: 100%; height: 400px"
    >
      <GmapMarker
        ref="myMarker"
        :position="google && new google.maps.LatLng(latitude, longitude)"
      />
    </GmapMap>
  </div>
</template>
<script>
import { gmapApi } from "vue2-google-maps";
export default {
  props:['latitude', 'longitude'],
  computed: {
    google: gmapApi
  }
}
</script>

<style lang="scss" scoped>
@import "resources/assets/sass/website/_variables.scss";
</style>