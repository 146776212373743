<template>
  <div>
    <form class="form-newsletter" @submit.prevent="submitForm()">
      <input aria-label="Nome" type="text" name="name" v-model="form.name" placeholder="Nome*" required>
      <the-mask aria-label="Telefone" type="tel" placeholder="Telefone*" v-model="form.phone" :mask="['(##) ####-####', '(##) #####-####']" required />
      <input aria-label="E-mail" type="email" name="email" v-model="form.email" placeholder="E-mail">
      <button type="submit" :disabled="disabled">{{btnText}}</button>
    </form>
  </div>
</template>
<script>
import {TheMask} from 'vue-the-mask'
import axios from 'axios'
export default {
  props: ['route', 'course'],
  components: {TheMask},
  data(){
    return {
      form: {
        name: '',
        email: '',
        phone: ''
      },
      disabled: false,
      btnText: "Enviar"
    }
  },
  created() {
    if (this.course && this.course != '') {
      this.form.course = this.course;
    }
  },
  methods: {
    submitForm() {
      this.btnText = 'Enviando'
      this.disabled = true

      axios.post(this.route, this.form)
      .then(response => {
        console.log(response);
        
        this.resetForm()
        this.btnText = 'Enviar'
        this.disabled = false
        this.$swal("Obrigado :D", "Dados enviados com sucesso!", "success")
      })
      .catch(e => {
        this.btnText = 'Enviar'
        this.disabled = false
        this.$swal("Oops!", "Houve um erro ao enviar seus dados! Por favor, tente novamente.", "error")
        console.error(e)
      })
    },
    resetForm () {
      this.form = {
        name: '',
        email: '',
        phone: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "resources/assets/sass/website/_variables.scss";

.form-newsletter{
    input{
        width: 100%;
        margin-bottom: 15px;
        border: 2px solid $primary;
        border-radius: 50px;
        padding: 15px;
        color: $text;
        font-size: 16px;
        line-height: 16px;
    }
    button{
        border-radius: 50px;
        background-color: transparent;
        border: 2px solid $primary;
        font-size: 16px;
        color: $primary;
        width: 100%;
        padding: 11px 15px;
    
        &:hover{
            color: $primary;
        }
    }
}

</style>